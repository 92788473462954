import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import { Suspense, lazy, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import DarkMode from "./utils/DarkMode";
import { NoData } from "./components/no-data/NoCards.jsx";
import { PrivateRoute } from "./PrivateRoute";
import PCLiveLoader from "./components/loader/PCLiveLoader.jsx";
import { onMessageListener } from "./firebase.js";
import {
  getAllNotifications,
  receivedNewNotification,
} from "./redux/slices/auth.js";

const Login = lazy(() => import("./screens/auth/login/Login"));
const Register = lazy(() => import("./screens/auth/register/Register"));
const ForgotPassword = lazy(() =>
  import("./screens/auth/forgot-password/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("./screens/auth/reset-password/ResetPassword")
);
const OTP = lazy(() => import("./screens/auth/otp/OTP"));
const FAQ = lazy(() => import("./screens/pages/faq/FAQ.js"));
const Policy = lazy(() => import("./screens/pages/policy/Policy.js"));
const TermsandConditions = lazy(() =>
  import("./screens/pages/termsConditions/TermsandConditions.js")
);
const Dashboard = lazy(() => import("./screens/pages/dashboard/Dashboard"));
const Auction = lazy(() => import("./screens/pages/auction/Auction"));
const Profile = lazy(() => import("./screens/pages/profile/Profile"));
const Header = lazy(() => import("./screens/pages/header/Header"));
const Landing = lazy(() => import("./screens/pages/landing/Landing"));

function App() {
  const location = useLocation();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const newNotificationsCount = useSelector(
    (state) => state.notification.newNotificationsCount
  );
  const userInfo = useSelector((state) => state.profile.data);

  const hideHeader = [
    "/login",
    "/register",
    "/landing",
    "/forgotPassword",
    "/resetPassword",
    "/otp",
    "/auction",
    "/policy",
    "/termsandconditions",
  ];

  const dispatch = useDispatch();

  if ("serviceWorker" in navigator) onMessageListener();

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    const preventDragHandler = (event) => {
      event.preventDefault();
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);
    window.addEventListener("dragstart", preventDragHandler);
    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
      window.removeEventListener("dragstart", preventDragHandler);
    };
  }, []);

  useEffect(() => {
    let timeoutId;

    const handleMessage = (event) => {
      if (
        event.data &&
        event.data.type === "NEW_NOTIFICATION" &&
        event.data?.action !== "CARD_ALERT"
      ) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          dispatch(getAllNotifications());
          dispatch(receivedNewNotification());
          localStorage.setItem(
            "newNotificationsCount",
            newNotificationsCount + 1
          );
          localStorage.setItem("isNotificationsOpened", false);
          localStorage.setItem("newNotificationsDate", new Date());
        }, 10);
      }
    };

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", handleMessage);
    }

    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", handleMessage);
      }
    };
  }, [dispatch, newNotificationsCount]);

  useEffect(() => {
    userInfo?.data &&
      localStorage.setItem("userDetails", JSON.stringify(userInfo?.data));
  }, [userInfo]);

  return (
    <div className="App">
      <Suspense fallback={<PCLiveLoader open />}>
        {isOnline ? (
          <>
            <ToastContainer
              position="top-center"
              autoClose={4000}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              limit={2}
            />
            <DarkMode />
            {!hideHeader.includes(location.pathname) && <Header />}
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/register" element={<Register />} />
              <Route
                exact
                path="/forgotPassword"
                element={<ForgotPassword />}
              />
              <Route exact path="/resetPassword" element={<ResetPassword />} />
              <Route exact path="/otp" element={<OTP />} />
              <Route exact path="/faq" element={<FAQ />} />
              <Route
                exact
                path="/termsandconditions"
                element={<TermsandConditions />}
              />
              <Route exact path="/policy" element={<Policy />} />
              <Route
                path="/dashboard"
                element={<PrivateRoute component={Dashboard} />}
              />
              <Route
                path="/auction"
                element={<PrivateRoute component={Auction} />}
              />
              <Route
                path="/profile"
                element={<PrivateRoute component={Profile} />}
              />
              <Route
                path="/landing"
                element={<PrivateRoute component={Landing} />}
              />
              <Route
                path="*"
                element={<Navigate to={"/dashboard"} replace />}
              />
            </Routes>
          </>
        ) : (
          <NoData type="lostConnection" />
        )}
      </Suspense>
    </div>
  );
}
export default App;
