import axios from "axios";
import { logout } from "../redux/slices/auth";

export const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + getToken(),
  };
};

export const getToken = () => {
  return localStorage.getItem("accessToken");
};

export const isTokenExpired = () => {
  const tokenExpiry = localStorage.getItem("tokenExpiry");
  if (tokenExpiry) {
    const expiryTime = new Date(tokenExpiry.split(".")[0] + "Z");
    const currentTime = new Date();

    return currentTime > expiryTime;
  }
  return;
};

export const handleSessionTimeout = () => {
  var allowdata = localStorage.getItem("allowdata");
  localStorage.clear();
  localStorage.setItem("allowdata", allowdata);
  return (window.location.href = "/login");
};

axios.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error?.response?.status === 410 ||
      error?.response?.status === 440 ||
      error?.response?.message?.toLowerCase() === "session expired"
    ) {
      const handleSuccessOrFail = (res) => {
        let allowdata = localStorage.getItem("allowdata");
        localStorage.clear();
        localStorage.setItem("allowdata", allowdata);
        return (window.location.href = "/login");
      };
      logout(handleSuccessOrFail, handleSuccessOrFail);
      return Promise.reject("Session expired");
    }
  }
);

export const apiPostCall = async (
  endpoint,
  userData,
  handleSuccess,
  handleFail,
  isFormData
) => {
  if (isTokenExpired()) handleSessionTimeout();
  else {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
        userData,
        {
          headers: {
            "Content-Type": isFormData ? "" : "application/json",
            Authorization: "Bearer " + getToken(),
          },
        }
      );
      if (response?.data?.success) handleSuccess(response);
      else handleFail(response?.data);
    } catch (error) {
      handleFail(error);
      console.error(error);
    }
  }
};

export const apiPutCall = async (
  endpoint,
  userData,
  handleSuccess,
  handleFail
) => {
  if (isTokenExpired()) handleSessionTimeout();
  else {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
        userData,
        {
          headers: getHeaders(),
        }
      );
      if (response?.data?.success) handleSuccess(response);
      else handleFail(response?.data);
    } catch (error) {
      handleFail(error);
      console.error(error);
    }
  }
};

export const apiPutCallForFormData = async (
  endpoint,
  userData,
  handleSuccess,
  handleFail
) => {
  if (isTokenExpired()) handleSessionTimeout();
  else {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
        userData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + getToken(),
          },
        }
      );
      if (response?.data?.success) handleSuccess(response);
      else handleFail(response?.data);
    } catch (error) {
      handleFail(error);
      console.error(error);
    }
  }
};

export const apiGetCall = async (endpoint, handleSuccess, handleFail) => {
  if (isTokenExpired()) handleSessionTimeout();
  else {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
        {
          headers: getHeaders(),
        }
      );
      if (response?.data?.success) handleSuccess(response);
      else handleFail(response?.data);
    } catch (error) {
      handleFail(error);
      console.error(error);
    }
  }
};

export const apiDeleteCall = async (endpoint, handleSuccess, handleFail) => {
  if (isTokenExpired()) handleSessionTimeout();
  else {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
        {
          headers: getHeaders(),
        }
      );

      if (response?.data?.success) handleSuccess(response);
      else handleFail(response?.data);
    } catch (error) {
      handleFail(error);
      console.error(error);
    }
  }
};

export const apiPatchCall = async (
  endpoint,
  userData,
  handleSuccess,
  handleFail
) => {
  if (isTokenExpired()) handleSessionTimeout();
  else {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
        userData,
        {
          headers: getHeaders(),
        }
      );
      if (response?.data?.success) handleSuccess(response);
      else handleFail(response?.data);
    } catch (error) {
      handleFail(error);
      console.log(error);
    }
  }
};

export const showData = (data) => {
  switch (data) {
    case "PRE_TRANSIT":
      return "PROCESSING";
    case "TRANSIT":
      return "SHIPPED";
    case "DELIVERED":
      return "DELIVERED";
    case "RETURNED":
      return "RETURNED";
    case "CANCELLED":
      return "CANCELLED";
    case "FAILURE":
      return "FAILED";
    case "ORDER_RECEIVED":
      return "ORDER RECEIVED";
    case "UNKNOWN":
      return "ORDER RECEIVED";
    default:
      return data;
  }
};

export const deepCopy = (obj, seen = new Map()) => {
  // Return the value if obj is not an object
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // Handle circular references
  if (seen.has(obj)) {
    return seen.get(obj);
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    const copy = [];
    seen.set(obj, copy);
    for (let i = 0; i < obj.length; i++) {
      copy[i] = deepCopy(obj[i], seen);
    }
    return copy;
  }

  // Handle objects
  const copy = {};
  seen.set(obj, copy);
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepCopy(obj[key], seen);
    }
  }
  return copy;
};

export const formatNumber = (num) => {
  if (num >= 1e6) {
    return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // For numbers in millions
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // For numbers in thousands
  }
  return num.toString(); // For smaller numbers
};