import { useEffect } from "react";

import { setGlobalDarkMode } from "../redux/slices/profile";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const DarkMode = (props) => {
  const { toggle } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const storedDarkMode = localStorage.getItem("darkMode") || "true";

  useEffect(() => {
    const isDarkMode =
      storedDarkMode === "true" || location.pathname === "/auction";
    const root = document.documentElement;
    dispatch(setGlobalDarkMode(storedDarkMode === "true"));

    const themeVariables = {
      "--gray-25": "#FCFCFD",
      "--gray-50": "#F9FAFB",
      "--gray-100": "#F2F4F7",
      "--gray-200": "#EAECF0",
      "--gray-300": "#D0D5DD",
      "--gray-400": "#98A2B3",
      "--gray-500": "#667085",
      "--gray-600": "#475467",
      "--gray-700": "#344054",
      "--gray-800": "#1D2939",
      "--gray-900": "#101828",
      "--error-25": "#FFFBFA",
      "--error-50": "#FEF3F2",
      "--error-100": "#FEE4E2",
      "--error-300": "#FDA29B",
      "--error-400": "#F97066",
      "--error-500": "#F04438",
      "--error-600": "#D92D20",
      "--error-700": "#B42318",
      "--white": "#ffffff",
      "--primary-25": "#F6FAFF",
      "--primary-50": "#ECF6FF",
      "--primary-100": "#C6D6FF",
      "--primary-300": "#6F91E8",
      "--primary-600": "#0E54CA",
      "--primary-700": "#004ABC",
      "--primary-500": "#175CD3",
      "--backgroundColor": "#F2F4F7",
      "--primary-800": "#0040B0",
      "--success-50": "#ECFDF3",
      "--success-600": "#039855",
      "--success-700": "#027A48",
      "--success-900": "#054F31",
      "--theme-700": "#ffffff",
      "--dark-theme-600": "#3B3A3A",
      "--background-theme": "#000000",
      "--background-switch": "#ffffff",
      "--dark-theme-800": "#0D0C0C",
      "--dark-theme-700": "#292828",
      "--dark-theme-300": "#9D9C9C",
      "--dark-theme-25": "#DBDBDB",
      "--dark-theme-750": "#1C1D1D",
      "--Blue-300": "hsl(223, 72%, 67%)",
    };

    const themeVariablesDark = {
      "--gray-25": "#FCFCFD",
      "--gray-50": "#F9FAFB",
      "--gray-100": "#F2F4F7",
      "--gray-200": "#EAECF0",
      "--gray-300": "#D0D5DD",
      "--gray-400": "#98A2B3",
      "--gray-500": "#667085",
      "--gray-600": "#D0D5DD",
      "--gray-700": "#D0D5DD",
      "--gray-800": "#F9FAFB",
      "--gray-900": "#101828",
      "--error-25": "#FFFBFA",
      "--error-50": "#FEF3F2",
      "--error-100": "#FEE4E2",
      "--error-300": "#FDA29B",
      "--error-400": "#F97066",
      "--error-500": "#F04438",
      "--error-600": "#D92D20",
      "--error-700": "#B42318",
      "--error-900": "#7A271A",
      "--white": "#ffffff",
      "--primary-25": "#F6FAFF",
      "--primary-50": "#F9F5FF",
      "--primary-100": "#C6D6FF",
      "--primary-200": "#99B2F8",
      "--primary-500": "#6F91E8",
      "--primary-300": "#6F91E8",
      "--primary-600": "#0E54CA",
      "--primary-700": "#6F91E8",
      "--primary-800": "#D0D5DD",
      "--backgroundColor": "#0D0C0C",
      "--theme-700": "#1C1D1D",
      "--dark-theme-600": "#3B3A3A",
      "--background-theme": "#000000",
      "--dark-theme-800": "#0D0C0C",
      "--dark-theme-700": "#292828",
      "--dark-theme-300": "#9D9C9C",
      "--dark-theme-25": "#DBDBDB",
      "--dark-theme-750": "#1C1D1D",
      "--background-switch": "#000000",
      "--success-50": "#ECFDF3",
      "--success-300": "#6CE9A6",
      "--success-600": "#039855",
      "--success-700": "#027A48",
      "--success-900": "#054F31",
      "--blue-300": "hsl(223, 72%, 67%)",
      "--warning-900": "#7A2E0E",
    };
    const selectedThemeVariables = isDarkMode
      ? themeVariablesDark
      : themeVariables;
    // Save the current values to revert them when the component is unmounted
    const originalValues = {};
    const elements = document.querySelectorAll("*");

    // Loop through each div and add the class
    elements.forEach((div) => {
      if (isDarkMode) {
        div.classList.add("pclive-dark-mode");
      } else {
        div.classList.remove("pclive-dark-mode");
      }
    });
    // Set the new values
    Object.entries(selectedThemeVariables).forEach(([key, value]) => {
      originalValues[key] = root.style.getPropertyValue(key);
      root.style.setProperty(key, value);
    });
    // Cleanup function
    return () => {
      Object.entries(selectedThemeVariables).forEach(([key, value]) => {
        root.style.setProperty(key, value);
      });
    };
  }, [toggle, location.pathname, storedDarkMode, dispatch]);
};

export default DarkMode;
