// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  generateRealTimeToken,
  getAllNotifications,
  receivedNewNotification,
} from "./redux/slices/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB7dNavXJ0RYR-kzNKezktXBqFG5F8tRnA",
  authDomain: "pclive247app.firebaseapp.com",
  projectId: "pclive247app",
  storageBucket: "pclive247app.appspot.com",
  messagingSenderId: "1038051254697",
  appId: "1:1038051254697:web:948faef027dcb6e21724b8",
  measurementId: "G-T37ZP7X2JK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

let messaging;
if ("serviceWorker" in navigator) {
  messaging = getMessaging(app);
}

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BOS9IHp0LltWH2JvmcFCjjEgxmhpN2SC6VAt3ZA7hdkuXL2NcaHD0cSC4hlYXTZ_8kMMguDgB0OrNJJkAfv6reA",
  })
    .then((currentToken) => {
      if (currentToken) {
        generateRealTimeToken(
          {
            web_device_token: currentToken,
          },
          (body) => {},
          (body) => {
            toast.error(body?.message);
          }
        );
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

// Function for handling foreground notifications
export const onMessageListener = () =>
  new Promise((resolve) => {
    const newNotificationsCount = useSelector(
      (state) => state.notification.newNotificationsCount
    );
    const dispatch = useDispatch();

    onMessage(messaging, (payload) => {
      if (payload?.data?.click_action !== "CARD_ALERT") {
        dispatch(getAllNotifications());
        dispatch(receivedNewNotification());
        localStorage.setItem(
          "newNotificationsCount",
          newNotificationsCount + 1
        );
        localStorage.setItem("isNotificationsOpened", false);
        localStorage.setItem("newNotificationsDate", new Date());
      }
      resolve(payload);

      // Register the service worker to handle notifications
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then(function (registration) {
          const notificationTitle = payload?.notification?.title;
          const notificationOptions = {
            body: payload?.notification?.body,
          };
          registration.showNotification(notificationTitle, notificationOptions);
        });
      }
    });
  });